import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'
import * as getters from './getters'
import * as actions from './actions'
import mutations from './mutations'
import users from '../../../shared/store_modules/users'
import userCollections from '../../../shared/store_modules/user_collections'
import categorySystems from '../../../shared/store_modules/category_systems'
import productCategories from '../../../shared/store_modules/product_categories'
import orders from '../../../shared/store_modules/orders'
import orderItems from '../../../shared/store_modules/order_items'
import CurrentUserAssignService from '../../../shared/services/current_user_assign_service'

Vue.use(Vuex)

const state = {
  currentUser: CurrentUserAssignService.call('babywearing'),
  flashMessages: [],
  queryString: {},
  retryCounter: 0,
  device: {
    isMobile: false,
    deviceType: '',
    windowWidth: 0
  },
  mobileMenuStates: {
    isMobileMenuActive: false,
    activatedCategoryType: '',
    isCategoriesSelectorActive: false,
    selectedTopLevelCategoryId: null,
    isSkippingRootMenuToClose: false
  }
}

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules: {
    users,
    userCollections,
    categorySystems,
    productCategories,
    orders,
    orderItems
  }
})
