export default {
  name: 'event-modal-container',

  // components: {},

  // mixins: [],

  // props: {},

  data() {
    return {
      isEventModalShow: false,
      eventModalName: 'event-model-20190422-v2'
    }
  },

  // computed: {},

  // created() {},

  mounted() {
    this.$vlf.getItem(`disabled-event-modal`).then((value) => {
      if (value !== this.eventModalName) {
        this.bindingScrollEvent()
      }

      return true
    })
  },

  methods: {
    disableModalShow() {
      this.$vlf.setItem('disabled-event-modal', this.eventModalName)
    },

    bindingScrollEvent() {
      window.addEventListener('scroll', this.showModal)
    },

    showModal() {
      window.removeEventListener('scroll', this.showModal)

      setTimeout(() => {
        this.isEventModalShow = true
      }, 2000)
    }
  }
}
